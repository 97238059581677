<template>
  <div class="stepComponent">
    <div class="stepsTitle">
      <div
        style="float: left; width: 2px; height: 20px; background: #219aff"
      ></div>
      打卡流程
    </div>
    <div class="approvalProcess">
      <el-steps
        :active="active"
        finish-status="success"
        space="50px"
        direction="vertical"
      >
        <el-step
          v-for="item in approvalProcessProject"
          :id="item.id"
          :icon="item.icon"
          :key="item"
        >
          <template slot="title">
            <div class="slot_title">
              <div>{{ item.label }}</div>
              <div>{{ item.timePoint }}</div>
              <div><i class="el-icon-user"></i></div>
            </div>
          </template>
          <!-- <template slot="description">
            <div
              class="step-row"
              v-for="item in approvalProcessProject"
              :key="item"
            >
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                class="processing_content"
              >
                <tr>
                  <td style="color: #98a6be">
                    <div
                      class="processing_content_detail"
                      style="float: left; width: 70%"
                    >
                      <span
                        >申请人&nbsp;&nbsp;<span style="color: #219aff"
                          >圆领{{}}</span
                        >&nbsp;&nbsp;提交了割接方案</span
                      >
                    </div>
                    <div class="processing_content_detail" style="float: right">
                      <span
                        ><i class="el-icon-time"></i>&nbsp;&nbsp;昨天12:24</span
                      >
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      class="processing_content_detail"
                      style="float: left; width: 70%"
                    >
                      <div
                        style="
                          float: left;
                          width: 2px;
                          height: 20px;
                          background: #c7d4e9;
                          margin-left: 10px;
                          margin-right: 10px;
                        "
                      ></div>
                      <span style="color: #919fb8">同意，建议通过</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </template> -->
        </el-step>
      </el-steps>
      <el-button style="margin-top: 12px" @click="next">Check point</el-button>
    </div>
  </div>
</template>
<script>
import DeleteCompany from "../../components/deleteCompany/deleteCompany.vue";
export default {
  components: { DeleteCompany },
  props: ["data", "defaultActive"],
  data() {
    return {
      active: 0,
      approvalProcessProject: [
        {
          id: "0",
          label: "Create Order",
          icon: "el-icon-circle-plus",
          timePoint: "2022-03-02",
        },
        {
          id: "1",
          label: "Confirm Order",
          icon: "el-icon-success",
          timePoint: "2022-03-02",
        },
        {
          id: "2",
          label: "Start production",
          icon: "el-icon-shopping-cart-2",
          timePoint: "2022-03-03",
        },
        {
          id: "3",
          label: "Confirm forwarder",
          icon: "el-icon-user-solid",
          timePoint: "2022-03-06",
        },
        {
          id: "4",
          label: "Ready for shippment",
          icon: "el-icon-location-information",
          timePoint: "2022-03-20",
        },
        {
          id: "5",
          label: "Oringin warehouse",
          icon: "el-icon-s-home",
          timePoint: "2022-03-20",
        },
        {
          id: "6",
          label: "Oringin port",
          icon: "el-icon-ship",
          timePoint: "2022-03-22",
        },
        {
          id: "7",
          label: "Destination port",
          icon: "el-icon-ship",
          timePoint: "2022-05-01",
        },
        {
          id: "8",
          label: "Customs clearance",
          icon: "el-icon-s-custom",
          timePoint: "2022-05-02",
        },
        {
          id: "9",
          label: "Arrive destination",
          icon: "el-icon-s-home",
          timePoint: "2022-05-09",
        },
      ],
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    next() {
      if (this.active++ > this.approvalProcessProject.length) this.active = 0;
    },
  },
};
</script>
<style lang="less" scoped>
@mainBlue: rgb(75, 102, 131);
@mainBlueHover: rgba(75, 102, 131, 0.8);
/deep/.el-step__head.is-success {
  color: @mainBlue;
  border-color: @mainBlue;
}

/deep/.el-step__title.is-success {
  color: @mainBlue;
}

/deep/.el-step.is-vertical .el-step__line {
  width: 2px;
  top: 0;
  bottom: 0;
  left: 11px;
}

.slot_title {
  display: flex;
  flex-direction: row;
  width: 100%;
  div:nth-child(1) {
    font-size: 14px;
    //color: #838282;
    width: 20%;
    margin-right: 20px;
  }
  div:nth-child(2) {
    font-size: 12px;
    font-weight: 700;
    //color: #212020;
    width: 10%;
    margin-right: 20px;
  }
  div:nth-child(3) {
    font-size: 14px;
    font-weight: 700;
    color: #212020;
    width: 5%;
    margin-right: 20px;
    cursor: pointer;
  }
}

.stepComponent {
  background-color: #ffffff;
  width: 100%-20px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}
.stepsTitle {
  margin: 10px 0px 10px 10px;
}
.approvalProcess {
  /* color: #9eadc4; */
  font-size: 14px;
  /* width: 100%; */
  /* background: #dfebff; */
  margin-left: 20px;
  margin-right: 0px;
  margin-top: 10px;
}
.processing_content {
  background-color: #edeeef;
}
.processing_content_detail {
  margin-left: 10px;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
  width: 150px;
  display: inline-block;
}
.step-row {
  min-width: 500px;
  margin-bottom: 12px;
  margin-top: 12px;
}
</style>
