<template>
  <el-dialog
    title="Delete company"
    :before-close="handleClose"
    :visible.sync="deleteCompanyShow"
    :show-close="false"
    width="35%"
    class="outDialog"
    v-move-outside
  >
    <div class="dialog_body">
      <div class="close" @click="handleClose">
        <i class="el-icon-circle-close"></i>
      </div>
      <span>Are you sure to delete this company?</span>
      <div class="body_inner">
        <div class="company_pic">
          <img :src="company_url" alt="" srcset="" />
        </div>
        <div class="company_info">
          <div>Name: EET GROUP</div>
          <div>Type: Vendor</div>
          <div>Country: Germany</div>
        </div>
      </div>
    </div>
    <el-dialog
      width="30%"
      title="Delete company"
      :visible.sync="innerVisible"
      append-to-body
      class="inDialog"
      :show-close="false"
      v-move-outside
    >
      <div class="dialog_body">
        <span>Are you sure to delete this company?</span>
        <div class="body_inner">
          <div class="company_pic">
            <img :src="company_url" alt="" srcset="" />
          </div>
          <div class="company_info">
            <div>Name: EET GROUP</div>
            <div>Type: Vendor</div>
            <div>Country: Germany</div>
          </div>
        </div>
        <div class="doublecheck">
          <div class="doublecheck_text">
            Please enter company name to double check:
          </div>
          <el-input
            size="middle"
            v-model="piCheck"
            clearable
            placeholder="Type company name..."
            class="input_pi"
          ></el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAlldialog()">Cancel</el-button>
        <el-button type="danger" @click="cancelAlldialog()">Delete</el-button>
      </span>
    </el-dialog>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancelAlldialog()">Cancel</el-button>
      <el-button type="primary" @click="innerVisible = true"
        >Yes, delete this company</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      innerVisible: false,
      test: this.deleteCompanyShow,
      company_url: "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/img1.png",
      piCheck: null,
    };
  },
  props: ["deleteCompanyShow"],
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    cancelAlldialog() {
      this.innerVisible = false; //为了内部的dialog复用该函数
      this.$emit("update:deleteCompanyShow", false);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  border-radius: 8px 8px 0 0;
  background-color: rgb(204, 204, 202);
  font-weight: 700;
  padding: 5px 0 5px 5px;
}
// .inDialog .el-dialog__header {
//   border-radius: 8px 8px 0 0;
//   background-color: rgb(204, 204, 202);
//   font-weight: 700;
//   padding: 5px 0 5px 5px;
// }
.dialog_body {
  position: relative;

  .close {
    position: absolute;
    top: -57px;
    right: 0;

    i {
      font-size: 22px;
      cursor: pointer;
    }
  }
}
.body_inner {
  padding: 10px 10px 10px;
  margin: 8px 0 20px;
  border: 1px solid rgb(189, 188, 188);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.company_pic {
  background-color: rgb(233, 231, 231);
  padding: 0 10px 0 10px;
  margin: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    // height: 60%;
    &:hover {
      transform: scale(1.2);
      transition: all 0.3s;
    }
  }
}
.company_info {
  font-size: 16px;
  font-weight: 700;
}
.doublecheck {
  margin: 10px 0;
  .doublecheck_text {
    color: red;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
// .input_pi .el-input__inner {
//   width: 70%;
//   background-color: aqua;
//   border-color: red;
// }
.input_pi {
  width: 70%;
}
// .input_pi:hover {
//   width: 100%;
// }
</style>
